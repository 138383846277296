<template>
<!-- 嘉宾库 -->
<div>
    <div class="attend">
        <van-search class="search" shape="round" v-model="keywords" placeholder="搜索" @input="retrieval()"/>
        <van-index-bar id="scroll" :index-list="Object.keys(retrievallist)" ref="indexbar" :highlight-color="themecolors" :sticky-offset-top='0'>
            <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading" />
            <van-empty :image="require('@/assets/successful.svg')" v-else-if="Object.keys(retrievallist).length === 0">
                暂无相关人员
            </van-empty>
            <div v-for="(value,key1) in retrievallist" :key="key1">
                <van-index-anchor :index="key1" :class="{active_acnchor:showUserheader}"/>
                <div class="user flex-center" v-for="(i,j) in value" :key="j" @click="attendInfo(i, key1)">
                    <div class="avatar flex-center">
                        <img :src="i.avatar_url" alt="">
                    </div>
                    <div class="user-info">
                        <p class="hospital">{{i.name}}</p>
                        <span class="hospital">{{i.hospital}}</span>
                    </div>
                </div>
            </div>
        </van-index-bar>
    </div>
    <sideBar></sideBar>
</div>
</template>

<script>
import sideBar from '@/components/sidebar';
import { mapState } from 'vuex'
export default {
    components:{
        sideBar,
    },
    props: {
        showUserheader: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            Loading:false,
            keywords:"",
            params:{},
            list:[],
            indexList: [ '*', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
            retrievallist:[],// 检索列表
        }
    },
    created(){
        if (this.schedulevalue) {
            this.keywords = this.schedulevalue
        }
         this.params = this.$route.query;
         this.get_list_expert_contact_list();
    },
    computed: {
        ...mapState(['schedulevalue', 'themecolors'])
    },
    methods:{
        // 前端检索功能
        retrieval(){
            this.retrievallist={}
            this.indexList.forEach(element =>{
                if(this.list[element]){
                    this.list[element].forEach(item =>{
                        if(item.name.toLowerCase().indexOf(this.keywords.toLowerCase()) != -1){
                            if(this.retrievallist[element]){
                                this.retrievallist[element].push(item)
                            }else{
                                this.retrievallist[element] = [item]
                            }
                        }
                    })
                }
               
            })
        },
        attendInfo(i, key){
            if(this.$route.query.singePage == 'singePage') {
                this.$router.push({ name: 'attendIntro', query: {
                    site_id:this.params.site_id,
                    singePage: 'singePage',
                    backtrack: 'backtrack',
                    site_url: this.$route.query.site_url
                }});
            } else {
                // this.$router.push("/attend_intro");
                this.$router.push(({name:'attendIntro',query: this.params}));
            }
            this.$cookies.set('people_id', i.id)
            window.sessionStorage.setItem('listkey', key)
            window.sessionStorage.setItem("attendIntro",JSON.stringify(i))
        },
        // 获取参会者
        get_list_expert_contact_list(){
            this.Loading = true;
            this.$store.dispatch("get_list_expert_contact_list",{
                site_id:this.params.site_id,
                keywords:this.keywords,
                module_id:this.params.module_id,
            }).then(res=>{
                this.Loading = false;
                this.$store.commit('changeschedulevalue', '')
                if(res.data.code == 200){
                    this.list = res.data.data.module_content;
                    this.indexList.forEach(element =>{
                        if(this.list[element]){
                            this.list[element].sort((a,b)=>a.name.localeCompare(b.name,'zh'))
                        }
                    })
                    this.retrievallist=this.list
                    document.title = res.data.data.module_title ? res.data.data.module_title : '轻微站';
                    let key = window.sessionStorage.getItem('listkey')
                    if (key) {
                        this.$nextTick(() => {
                            this.$refs.indexbar.scrollTo(key)
                            window.sessionStorage.setItem('listkey', '')
                        })
                    }
                    this.retrieval()
                }
            }).catch(err => {
                // console.log(err)
                this.$store.commit('changeschedulevalue', '')
            })
        }
    }
}
</script>

<style lang='less' scoped>
.active_acnchor{
    /deep/.van-index-anchor--sticky,/deep/.van-index-bar__index--active{
        top: -0.4rem;
    }
}
/deep/.van-empty,/deep/.van-loading{
    position: absolute;
    top: 30%;
    left:50%;
    transform:translate(-50%,-30%);
}
/deep/.van-empty{
    width: 100%;
    .van-empty__image{
        width: 3rem;
        height: 3rem;
    }
    .van-empty__bottom{
        font-size: 0.14rem;
        color: #999;
    }
}
/deep/.van-loading {
    top: 50%;
}
.attend{
    height: 100%;
    box-sizing: border-box;
    // overflow-y: scroll;
    .flex-center{
        display: flex;
        align-items: center;
    }
    .search{
        position: relative;
        width: 100%;
        height: 0.54rem;
        z-index: 9999999;
    }
    .user{
        border-bottom: 0.01rem solid #F7F8FA;
        margin: .1rem .2rem 0;
        padding-bottom: .1rem;
    }
    .avatar{
        width: .35rem;
        height: .35rem;
        border-radius: 50%;
        overflow: hidden;
        margin-right: .1rem;
        img{
            width: 100%;
            height: auto;
        }
    }
    .user-info{
        flex: 1;
        p{
            font-size: .14rem;
        }
        span{
            display: block;
            font-size: .12rem;
            color: #969799;
        }
        .hospital{
            max-width: 2.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    /deep/.van-index-bar{
        height: calc(100% - .54rem);
        overflow-y: scroll;
        .van-index-bar__index{
            line-height: .18rem;
        }
    }
    /deep/.van-index-anchor{
        background: #F7F8FA;
    }
    /deep/.van-index-anchor--sticky,/deep/.van-index-bar__index--active{
        color: #2c3e50;
        left: 0px!important;
        
        // transform: translate3d(0px, 3rem, 0px)!important;
    }
    // /deep/.van-index-bar__index--active{
    //     color: #2c3e50!important;
    // }
}
</style>